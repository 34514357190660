
/* @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap'); */

@font-face {
    font-family: "HelveticaNeue";
    src: url("./assets/fonts/HelveticaNeueLight.ttf") format("truetype");
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: "HelveticaNeue";
    src: url("./assets/fonts/HelveticaNeueThin.ttf") format("truetype");
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: "HelveticaNeue";
    src: url("./assets/fonts/HelveticaNeueNormal.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: "HelveticaNeue";
    src: url("./assets/fonts/HelveticaNeueMedium.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: "HelveticaNeue";
    src: url("./assets/fonts/HelveticaNeueBold.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
}
@font-face {
    font-family: "HelveticaNeue";
    src: url("./assets/fonts/HelveticaNeueItalic.ttf") format("truetype");
    font-style: italic;
}

* {
    line-height: 1.3 !important;
}

/* * {
    font-family: "HelveticaNeue" !important;
    font-family: 'Roboto', sans-serif !important;
    line-height: 1.3 !important;
    letter-spacing: 0.5px !important;
} */